<template>
	<div class="partial partial--registrations registrations">
		<div class="partial__heading">
			<ion-icon name="construct-outline" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Tools</h1>
		</div>

		<div class="partial__body">
			<list-tools :tools="tools" />
		</div>

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import ListTools from '@/components/ListTools'
import Modal from '@/components/Modal'

export default {
	name: 'PartialTools',
	components: {
		ListTools,
		Modal,
	},
	computed: {
		tools() {
			let tools = [
				{
					name: 'Salesforce Sync',
					icon: 'infinite',
					link: {
						name: `salesforce`,
					},
				},
			]

			return tools
		},
		modalVisible() {
			return this.$route.name == 'salesforce'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
	},
	methods: {
		hideModal(clicked) {
			this.$router.replace({ name: 'tools' })
		},
	},
	metaInfo: () => ({
		title: `Tools`,
	}),
}
</script>

<style scoped lang="scss">

</style>
